var site = site || {};

site.direction = site.direction || {};

(function ($) {
  Drupal.behaviors.sliderV2Module = {
    attach: function (context) {
      $('.js-slide-module-v2', context).each(function () {
        var $module = $(this);
        var $slickWrapper = $('.js-slide-module__inner', $module);
        // Number of slides to display
        var dataSlidesToShow = $module.data('slides-to-show') || 1;
        // Number of slides to scroll
        var dataSlidesToScroll = $module.data('slides-to-scroll') || 1;
        // Slider autoplay checkbox
        var dataAutoplay = $module.data('autoplay') || false;
        // Nav Dots class present
        var $dotsOn = $module.data('dots-on');
        // Fade checkbox
        var $fadeSlides = $module.data('fade-slides');

        // Make Slick slider
        $slickWrapper.slick({
          rtl: site.direction.isRTL,
          dots: $dotsOn,
          infinite: true,
          speed: 500,
          autoplay: dataAutoplay,
          autoplaySpeed: 2000,
          slidesToShow: dataSlidesToShow,
          slidesToScroll: dataSlidesToScroll,
          adaptiveHeight: true,
          fade: $fadeSlides,
          prevArrow: $('.slick-prev', $module),
          nextArrow: $('.slick-next', $module)
        });
      });
    }
  };
})(jQuery);
